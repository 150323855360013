<template>
  <div>
    <iframe :src="signUrl" frameborder="0" width="100%" height="700px"></iframe>
  </div>
</template>

<script>
export default {
  created() {
    this.signUrl = this.$storage.getItem('commercialDiscountUrl')
    this.handleSuccess()
  },
  data() {
    return {
      signUrl: '',
    }
  },
  beforeDestroy() {
    this.destroyFn()
  },
  methods: {
    handleSuccess() {
      console.log(window.jpmt)
      this.destroyFn = window.jpmt.listenIframeMessageOnce('contract', () => {
        // 签约回调
        // do something
        console.log('签约成功')
        this.$message.success('签约成功')
        this.$router.push('/bill-financing')
      })
    },
  },
}
</script>
